import axios from "axios"
// localStorage.setItem('login_id',"");
let vlogin_id = localStorage.getItem('login_id');
class ApAdminUserService {

    login(form) {
        //console.log("local storage",localStorage.getItem('login_id'));
        //let form = {login_id:};
        //console.log(form);
        return axios.post("ap_admin_user/login", form);

    }

    change_own_password(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("ap_admin_user/change_own_password", form);

    }

    view_own_profile(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("ap_admin_user/view_own_profile", form);

    }

    update_own_profile(form) {
        vlogin_id = localStorage.getItem('login_id');
        form['login_id'] = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("ap_admin_user/update_own_profile", form);

    }
    

    

    
  
}

export default new ApAdminUserService();